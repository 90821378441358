<app-dialog-wrapper
  [componentName]="data.componentName"
  [title]="data.title"
  [progress]="(popupState$ | async) === 'loading'">
  <div class="popup">
    <div
      *ngIf="!ifAllFlatsNoPhone && showInfo"
      class="info">
      {{ 'services.popups.connections.manage.for_flats' | translate }}:
      <ng-container *ngFor="let address of addresses">
        <ng-container *ngIf="selectedFlatsObj[address.entrance.id]">
          {{ selectedFlatsObj[address.entrance.id] | flatsNumbers }}
          <p class="info-address">{{ address | address }}</p>
        </ng-container>
      </ng-container>
      {{ 'services.popups.connections.manage.message.you_can_only_change_control_of_intercom_pipe' | translate }}
    </div>
    <app-abonent-connection-tool-services
      class="abonent-connection-tool-content-services"
      [loading]="(popupState$ | async) === 'loading'"
      [avaliableServices]="services$ | async"
      [connectedServices]="connectedServices$ | async"
      [flatList]="flatList"
      [disableServices]="ifAllFlatsNoPhone"
      [showTubeCheckbox]="true"
      [flatConnections]="flatsConnections"
      (serviceSelected)="onChangeServicesSelection($event)"
      (tubeChanged)="
        onChangePhysicalTube($event.checked)
      "></app-abonent-connection-tool-services>
    <div class="popup-actions">
      <button
        mat-raised-button
        color="primary"
        class="popup-actions__button"
        [disabled]="(isLoading$ | async) || shouldDisableSubmitButton"
        (click)="saveServices()"
      >
        {{ 'services.popups.connections.manage.button.save' | translate }}
      </button>
    </div>
  </div>
</app-dialog-wrapper>

<app-component-loader
  *ngIf="isLoading$ | async"
  [background]="true"
  [whiteCircle]="false"
  [sizePx]="loadingSpinnerCircleSize"
></app-component-loader>
