<ng-container [ngSwitch]="pageMode$ | async">
  <app-abonent-connection-tool-content
    *ngSwitchCase="pageMode.CONNECTION"
    [phone]="phone"
    [entranceId]="getEntranceId()"
    [flatId]="flatId"
    [noPhone]="noPhone"
    [loading]="loading"
    [addresses]="addresses"
    [flatNumber]="flatNumber"
    [virtualFlat]="virtualFlat"
    [defaultService]="defaultService"
    [hasPhysicalTubeHidden]="hasPhysicalTubeHidden"
    [hasPhysicalTubeDefault]="hasPhysicalTubeDefault"
    [servicesSelectionRequired]="servicesSelectionRequired"
    [abonentAndAccountLoadingForced]="abonentAndAccountLoadingForced"
    [flatList]="flatList"
    [services]="services$ | async"
    [servicesLoading]="servicesLoading$ | async"
    [selectedServices]="selectedServices$ | async"
    [servicesForDelete]="servicesForDelete$ | async"
    [connectedServices]="connectedServices$ | async"
    [abonentDataDisabled]="abonentDataDisabled$ | async"
    (changePhysicalTubeAvailable)="
      onTubeAvailabilityChange($event.hasPhysicalTube)
    "
    (changeServiceSelection)="onChangeServicesSelection($event)"
    (submitConnection)="onSubmitConnection($event)"
    (abonentDataEntered)="
      onAbonentDataEntered($event.entranceId, $event.flatNumber, $event.phone)
    "></app-abonent-connection-tool-content>

  <app-account-selection
    *ngSwitchCase="pageMode.ACCOUNT_SELECTION"
    [loading]="loading"
    [abonent]="(abonentAndAccountsResponse$ | async).abonent"
    [accounts]="(abonentAndAccountsResponse$ | async).accounts"
    (accountSelected)="
      onAccountSelected($event.abonent, $event.account, $event.type)
    "
    (skipSelection)="
      onSkipSelected() 
    "></app-account-selection>
</ng-container>
