<div class="abonent-connection-tool">
  <app-info-block *ngIf="virtualFlat">
    <img
      info-block-image
      class="abonent-connection-tool-info__image"
      [src]="resourcesHelper.getResource(resourcePath.INFO_LG) | safeUrl"
    />
    <ng-container info-block-text>
      {{ 'shared.connections.tool.content.message.connect_virtual_apartment_test_service' | translate }}
    </ng-container>

    <ng-container info-block-content>
      <ng-container *ngFor="let range of getRangesFromEntrance()">
        <div class="abonent-connection-tool-info-ranges">
          <p class="abonent-connection-tool-info-ranges__text">
            {{ range.addressString }}
          </p>
          <p class="abonent-connection-tool-info-ranges__description">
            {{ range.rangesString }}
          </p>
        </div>
      </ng-container>
    </ng-container>
  </app-info-block>

  <div class="abonent-connection-tool-content">
    <form
      [formGroup]="form"
      autocomplete="off"
      class="abonent-connection-tool-content-form">
      <div class="abonent-connection-tool-content-form-address">
        <mat-form-field
          appearance="outline"
          class="none-padding mat-form-field-small"
        >
          <mat-label>
            {{ 'shared.connections.tool.content.field.address' | translate }}
          </mat-label>

          <mat-select formControlName="entranceId">
            <mat-option
              *ngFor="let address of addresses"
              [value]="address?.entrance.id">
              {{ address | address }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          appearance="outline"
          class="none-padding mat-form-field-small"
          *ngIf="!virtualFlat; else virtualFlatField"
        >
          <mat-label>
            {{ 'shared.connections.tool.content.field.flat' | translate }}
          </mat-label>
          <mat-select
            formControlName="flatNumber"
            required
          >
            <mat-option
              class="select-search-field"
              disabled
            >
              <input
                matInput
                type="text"
                autocorrect="off"
                spellcheck="false"
                autocomplete="off"
                autocapitalize="off"
                placeholder="{{'shared.connections.tool.content.placeholder.search' | translate }}"
                class="select-search-field__input"
                (keydown)="$event.stopPropagation()"
                (input)="onInputUpdate($event)"
              />
              <mat-progress-bar
                *ngIf="loading"
                mode="indeterminate"
                class="select-search-field-loader"
              ></mat-progress-bar>
            </mat-option>
            <mat-option
              *ngFor="let flatNumber of flatRange"
              [value]="flatNumber"
              [disabled]="ifFlatBusy(flatNumber)">
              {{ flatNumber }}
              <ng-container *ngIf="ifFlatBusy(flatNumber)">
                ({{ 'shared.connections.tool.content.message.busy' | translate }})
              </ng-container>
            </mat-option>
          </mat-select>
        </mat-form-field>

        <ng-template #virtualFlatField>
          <mat-form-field
            appearance="outline"
            class="none-padding mat-form-field-small">
            <mat-label>
              {{ 'shared.connections.tool.content.field.flat' | translate }}
            </mat-label>

            <input
              type="number"
              formControlName="flatNumber"
              matInput
              min="0"
              placeholder="{{'shared.connections.tool.content.placeholder.flat' | translate }}"
              autocomplete="off"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="false"
              required />
          </mat-form-field>
        </ng-template>
      </div>

      <div class="abonent-connection-tool-content-form-phone">
        <app-phone-input
          class="abonent-connection-tool-content-form-phone-number"
          formGroupName="phone"
        ></app-phone-input>
        <mat-slide-toggle
          class="abonent-connection-tool-content-form-phone-slider-toogle"
          *ngIf="
            noPhone &&
            !(phone && phone.toString() | phone) &&
            !selectedServices?.length
          "
          [labelPosition]="'after'"
          [disabled]="loading"
          [checked]="noPhoneChecked"
          (change)="onChangeNoPhoneChecked($event)"
        >
          {{ 'shared.connections.tool.content.message.no_phone_number' | translate }}
        </mat-slide-toggle>
      </div>
    </form>

    <mat-slide-toggle
      *ngIf="!hasPhysicalTubeHidden"
      class="abonent-connection-tool-content-form-phone-slider-toogle"
      [labelPosition]="'after'"
      [disabled]="loading"
      [checked]="hasPhysicalTubeDefault"
      (change)="onChangeHasPhysicalTube($event)">
      <div
        class="abonent-connection-tool-content-form-phone-slider-toogle-label">
        <p class="abonent-connection-tool-content-form-phone-slider-toogle-label__text">
          {{ 'shared.connections.tool.content.message.there_is_intercom_tube' | translate }}
        </p>
        <p class="abonent-connection-tool-content-form-phone-slider-toogle-label__description">
          {{ 'shared.connections.tool.content.message.turn_on_if_subscriber_has_tube_in_apartment' | translate }}
        </p>
      </div>
    </mat-slide-toggle>

    <app-abonent-connection-tool-services
      class="abonent-connection-tool-content-services"
      *ngIf="services?.length > 0 && !loading"
      [loading]="loading"
      [avaliableServices]="services"
      [connectedServices]="connectedServices"
      [defaultService]="defaultService"
      [noPhoneChecked]="noPhoneChecked"
      [flatConnections]="flatsConnections"
      (serviceSelected)="onServiceSelected($event)"
      (tubeChanged)="onHardwareIntercomeChecked($event.checked)">
      <div abonent-connection-tool-services-title>
        {{
          services?.length > 0
            ? defaultService
              ? ' ' + ('shared.connections.tool.content.message.additional_available_services' | translate)
              : ' ' + ('shared.connections.tool.content.message.choose_available_services' | translate)
            : ' ' + ('shared.connections.tool.content.message.no_accessible_services' | translate)
        }}
      </div>
    </app-abonent-connection-tool-services>
  </div>

  <div class="abonent-connection-tool-actions">
    <ng-container
      *ngIf="services?.length > 0 || noPhoneChecked; else continueButton">
      <button
        color="primary"
        mat-raised-button
        [disabled]="loading"
        (click)="onConnect()"
        class="abonent-connection-tool-actions__button mat-button-lg"
      >
        <ng-container *ngIf="servicesLoading; else buttonText">
          <app-component-loader [whiteCircle]="true"></app-component-loader>
        </ng-container>
        <ng-template #buttonText>
          {{ 'shared.connections.tool.content.button.connect' | translate}}
        </ng-template>
      </button>
    </ng-container>

    <ng-template #continueButton>
      <button
        color="primary"
        mat-raised-button
        [disabled]="loading || shouldDisableSubmitButton"
        (click)="onContinue()"
        class="abonent-connection-tool-actions__button mat-button-lg">
        <ng-container *ngIf="servicesLoading; else buttonText">
          <app-component-loader [whiteCircle]="true"></app-component-loader>
        </ng-container>

        <ng-template #buttonText>
          {{ 'shared.connections.tool.content.button.continue' | translate }}
        </ng-template>
      </button>
    </ng-template>
  </div>
</div>
